<template lang="">
  <v-row>
    <v-col cols="12" class="mt-2">
      <h1 class="display-1 font-weight-medium">Staff</h1>
    </v-col>
    <v-col>
      <v-data-table
        :headers="headers"
        :items="staff"
        :search="search"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row class="mt-2">
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Add New Staff
                  </v-btn>
                </template>
                <v-card v-if="dialog">
                  <v-form ref="form" @submit.prevent="save">
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-text-field
                          v-model="editedItem.title"
                          label="Title"
                          :rules="[rules.required]"
                          :loading="loading"
                          :disabled="loading"
                        ></v-text-field>

                        <v-select
                          v-model="editedItem.role"
                          :rules="[rules.required]"
                          label="Role"
                          :loading="loading"
                          :disabled="loading"
                          :items="['Super Admin', 'Staff']"
                        ></v-select>
                        <v-text-field
                          v-model="editedItem.email"
                          label="Email"
                          :loading="loading"
                          :disabled="loading || isEditing"
                          :rules="emailRules"
                        ></v-text-field>
                        <v-text-field
                          v-model="editedItem.phone"
                          label="Phone"
                          :loading="loading"
                          maxlength="10"
                          :disabled="loading"
                          :rules="[rules.req, rules.numOnly, rules.tenChars]"
                        ></v-text-field>
                        <!-- <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :rules="[rules.required]"
                        :loading="loading"
                        :disabled="loading"
                      ></v-text-field>
                      <v-text-field
                        v-model="editedItem.confirmPassword"
                        label="Confirm Password"
                        :rules="[
                          rules.required,
                          () =>
                            editedItem.password ===
                              editedItem.confirmPassword ||
                            'Passwords do not match.',
                        ]"
                        :loading="loading"
                        :disabled="loading"
                      ></v-text-field> -->
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :loading="loading"
                        :disabled="loading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :loading="loading"
                        :disabled="loading"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this staff?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDelete"
                    :loading="loading"
                    :disabled="loading"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    :loading="loading"
                    :disabled="loading"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mainApp, portalsApp } from "../firebase";
import formRules from "@/mixins/formRules";
const staff = portalsApp.firestore().collection("staff");
export default {
  mixins: [formRules],
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("staff", staff);
    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    loading: false,
    search: "",
    isEditing: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title",
        sortable: false,
      },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone", sortable: false },
      { text: "Role", value: "role" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    staff: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    rules: {
      required: (value) => !!value || "Required.",
    },
    phoneRules: [
      (v) => !!v || "Phone is required",
      // (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Staff" : "Edit Staff";
    },
    // loading() {
    //   return this.$store.state.loading;
    // },
  },

  watch: {
    dialog(val) {
      val || this.close();
      if (!val) {
        this.isEditing = false;
      }
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    editedItem(val) {
      // console.log(val);
    },
  },

  methods: {
    generateRandomPassword(length) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let password = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }

      return password;
    },
    editItem(item) {
      this.isEditing = true;
      this.editedIndex = this.staff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.staff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteStaff() {
      let doc = portalsApp
        .firestore()
        .collection("staff")
        .doc(this.staff[this.editedIndex].id);
      await doc.delete();
      this.closeDelete();
      this.loading = false;
    },

    async deleteItemConfirm() {
      const user = await this.$store.state.user;
      if (user.uid == this.staff[this.editedIndex].uid) {
        this.$toast.error(
          "You cannot delete as user is currently logged in to the system",
          {
            timeout: 4000,
          }
        );
        this.closeDelete();
        return;
      }
      this.loading = true;
      await this.$store.dispatch("setLoading", true);
      axios
        .post("/deleteUser", { uid: this.staff[this.editedIndex].uid })
        .then((res) => {
          this.deleteStaff();
        })
        .catch((error) => {
          this.$toast.error(error.message, {
            timeout: 4000,
          });
          this.closeDelete();
          this.loading = false;
        });

      await this.$store.dispatch("setLoading", false);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async addStaff(newUser) {
      await portalsApp
        .firestore()
        .collection("staff")
        .doc(newUser.uid)
        .set(newUser);

      this.loading = false;
      this.close();
    },
    async save() {
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      this.loading = true;

      if (this.editedIndex > -1) {
        let doc = portalsApp
          .firestore()
          .collection("staff")
          .doc(this.staff[this.editedIndex].id);
        await doc.update(this.editedItem);
        this.loading = false;
        this.close();
      } else {
        const staffsRef = await portalsApp
          .firestore()
          .collection("staff")
          .where("email", "==", this.editedItem.email);

        const staffArray = await staffsRef.get();
        if (staffArray.docs.length) {
          this.loading = false;
          this.$toast.error("This user already registered", {
            timeout: 4000,
          });
          return;
        }
        const password = this.generateRandomPassword(8);
        let newUser = this.editedItem;
        axios
          .post("/createStaff", {
            email: this.editedItem.email,
            password: password,
          })
          .then((userCredential) => {
            newUser.uid = userCredential.data.data.uid;
            this.addStaff(newUser);
            axios
              .post("/api/send-new-registration-email", {
                email: newUser.email,
                password: password,
              })
              .then((res) => {
                // console.log(res);
              })
              .catch((error) => {
                console.log(error);
                // ..
              });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error(error.message, {
              timeout: 4000,
            });
            this.loading = false;
            this.close();
          });
      }
      await this.$store.dispatch("setLoading", false);
    },
  },
};
</script>
